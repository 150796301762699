@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior: none;
  background: #efefef;
  font-family: 'Inter var', sans-serif;
  cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMCIgZmlsbD0iYmxhY2siLz48L3N2Zz4='),
    auto;
}

h1 {
  font-size: 30vw;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 0.7em;
  margin: 0;
  padding: 0;
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

.small {
  left: -60px;
}

.medium {
  left: -20px;
}

.big {
  left: 10px;
}

.content {
  color: #fff;
  transform: rotate(-90deg)!important;
  width: 450px;
  height: 60px;
  text-align: right;
}

.content h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  mix-blend-mode: difference;
}

.content h4 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  mix-blend-mode: difference;
}
.content span {
  color: red;
}
.overlay {
  z-index: 99;
}
.infowrapper {
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(120deg, rgb(246, 211, 101, 0.7) 0%, rgb(253, 160, 133,0.7) 100%);
    backdrop-filter: blur(8px);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 100;
}
.image img {
  max-width: 500px;
}
.closeme {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 101;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #333;
}
.closeme:after,
.closeme:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 50%;
  transform: rotate(45deg);
  background-color: #333;
  top: 10px;
  left: 16px;
  border-radius: 50%;
}
.closeme:before {
  transform: rotate(-45deg);
}

.info {
  width: 50%;
  padding: 50px 100px;
  background: #fff;
  margin: 50px;
  text-align: center;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.name span {
  color: red;
}
.description span {
  font-weight: 200;
}
.info h2, .info h3 {
margin: 0;
margin-top: 12px;
}

.framewrapper {
  position: absolute;
  right:40px;
  top:40px;
}

.framewrapper ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  font-size: 13px;
  margin: 0;
  padding: 0;
}
.framewrapper button {
padding: 5px 10px;
background-color: #333;
color: #fff;
font-weight: 400;
width: fit-content;
height: fit-content;
display: block;
border: none;
cursor: pointer;
}
.framewrapper button:hover {
  background-color: rgb(179, 46, 46);
  }
.framewrapper li span:first-child {
color: #f00;
font-weight: 400;
}
.framewrapper li span {
  color: #fff;
  font-weight: 200;
  }

.image {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.info h2 {
  font-weight: 400;
  text-transform: uppercase;
}

.info h3 {
  font-weight: 300;
}

.logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 20px;
}

.logo-img {
  margin: 10px;
}
.introbox {
  width:100%;
  height: 100%;
  position:fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 9999999999999;
  text-align: center;
}
.introbox img {
  max-width: 100%;
}
.introbox h2 {
  font-weight: 300;
}
.introbox h3:hover {
  font-weight: 400;
}
.introbox h3 {
  cursor: pointer;
  font-weight: 400;
  padding: 10px;
}
.mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .menutrigger {
    position: relative;
    width: 100%;
    height: 50px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    min-width: 50px;
}
  .menutrigger:after {
    content: "";
    width: 2px;
    height: 35px;
    position: absolute;
    background: #333;
    transform: rotate(90deg);
    top: 5px;
    right: 30px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.menutrigger:before {
  content: "";
  width: 2px;
  height: 35px;
  position: absolute;
  background: #333;
  transform: rotate(90deg);
  top: 15px;
  right: 30px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
  .slogantop {
    max-width: 50%;
    left: 20px!important;
    top: 20px!important;
  }
  .introbox {
    padding: 40px;
  }
  .logomain {
    bottom: 60px!important;
    left: 20px!important;
}
.sloganbottom {
  left: 20px!important;
  bottom: 20px!important;
}
.welcome {
  right: 20px!important;
  bottom: 20px!important;
}
.framewrapper {
  display: none;
}
.mobile {
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.mobile ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #fff;
  padding: 10px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.mobile li {
  margin: 5px 0;
}
.open:before {
  transform: rotate(-45deg);
  top: 5px;
}
.open:after {
  transform: rotate(45deg);
}
ul.open {
  opacity: 1!important;
  background: #fff;
}
.info {
  width: 100%;
  padding: 20px 5px;
  margin: 0;
}
.info p {
  font-size: 12px;
}
.image {
  height: auto;
}
.infowrapper {
  flex-direction: column;
  justify-content: center;
}
.image img {
  width: 100%;
}
}